import { render, staticRenderFns } from "./CompanyLogo.vue?vue&type=template&id=2e453938&scoped=true"
import script from "./CompanyLogo.vue?vue&type=script&lang=js"
export * from "./CompanyLogo.vue?vue&type=script&lang=js"
import style0 from "./CompanyLogo.vue?vue&type=style&index=0&id=2e453938&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_cache-loader@4.1.0_webpack@5.92.1__css-loader@6.1_63ue3ykrbzrbj3cit3tk6qbt3u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e453938",
  null
  
)

export default component.exports