<template>
  <header class="page-header">
    <div class="container">
      <div class="flex-row justify-between flex-center">
        <company-logo
          @click="logoLink"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import CompanyLogo from '@/components/header/CompanyLogo';

export default {
  name: 'HeaderBar',

  components: {
    CompanyLogo
  },
  mixins: [clickaway],
  methods: {
    logoLink() {
      window.location.href = 'https://rready.com';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/variables";

.page-header {
  background-color: white;

  .sign-link {
    text-transform: uppercase;
  }

  .header-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .wider-menu {
    line-height: 38px;
    width: 260px;
    top: 4.2rem;
    right: 0;
    z-index: 9999;
    padding: 0 0 0.6rem !important;

    .header-menu-item {
      padding: 0.2rem 1rem;
    }

    .header-menu-item-multiline {
      padding: 1rem 1.5rem 0.2rem 1.5rem;
    }

    .new-project-style {
      color: #2b94fc;
    }
  }

  .arrow-down {
    font-size: 6px;
    vertical-align: middle;
  }

  .request-count {
    background-color: $lighter-blue;
    color: $blue-fade;
    font-size: 11px;
    padding: 2px 10px;
    margin-left: 5px;
    border-radius: 50px;
  }

  .user-menu-container {
    position: relative;
  }
}

.header-message {
  background: #fdf6f6;

  .close-button {
    cursor: pointer;
  }
}

.create-project-btn-container {
  min-height: 10px;

  .create-project-btn {
    padding: 0;
    height: 36px;
    font-weight: 600;
    margin: 0.8rem 1.2rem;
    font-size: 12px;
  }
}

@media (min-width: 890px) {
  .user-avatar {
    display: none;
  }

  .menu-links-mobile {
    display: none;
  }

  .my-service-requests, .manage-offers {
    display: none;
  }
}

@media (max-width: 910px) {
  .header-menu__nav {
    display: none;
  }

  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 4.5rem;
    z-index: 999;
  }

  .slide-enter-active, .slide-leave-active {
    transition: background 0.3s ease-out;

    .header-menu {
      transition: all 0.3s ease;
    }
  }

  .slide-enter, .slide-leave-to {
    background-color: rgba(0, 0, 0, 0);

    .header-menu {
      transform: translateX(100%);
    }
  }

  .user-avatar {
    line-height: 17px !important;
    padding: 1rem;
  }

  .app-link > .header-menu {
    position: static;
    display: block;
    width: 100%;
  }

  .wider-menu {
    height: 100%;
    width: 280px !important;
    top: 0.1rem !important;

    .menu-links-mobile {
      padding-top: 0.5rem !important;
    }
  }

  .header-menu {
    overflow: auto;
    padding-right: 16px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    transform: translateX(0);
  }

  ::v-deep .header-menu-item {
    border-radius: 0 !important;
  }

  .menu-dropdown {
    margin: 0 !important;
    padding: 0 !important;
  }

  .user-menu-item {
    ::v-deep span {
      margin-left: 40px !important;
    }
  }

  .arrow-wrapper {
    flex-grow: 1;
  }
}

@media (max-width: 560px) {
  .page-header ::v-deep .pointer {
    display: none;
  }

  #avatar-toggle ::v-deep .user-info {
    display: none;
  }
}
</style>
