<template>
  <footer class="fw-12-500 page-footer">
    <div class="footer-bar">
      <div class="footer-content container">
        <div class="flex-col fw-14-600 title">
          <span>{{ $t("footer_bar.contact_us") }}</span>
        </div>
        <div class="flex-col address-container">
          <span>{{ line1 }}</span>
          <span>{{ footer.line2 }}</span>
          <span>{{ footer.line3 }}</span>
        </div>
        <div class="flex-col">
          <a :href="`mailto:${footer.email}`">{{ footer.email }}</a>
          <a
            :href="getValidUrl(footer.website)"
            target="_blank"
          >{{ footer.website }}</a>
          <span v-if="footer.phoneNumber">
            TEL: {{ footer.phoneNumber }}
          </span>
        </div>
      </div>
      <div
        v-if="Object.keys(footer).length && hasAtLeastOneDefaultUrl"
        class="flex-row justify-center container additional-footer-links"
      >
        <a
          class="cky-banner-element"
        >{{ $t('footer_bar.cookie_settings.label') }}
        </a>
        <a
          v-if="footer.url1 && footer.urlTitle1"
          :href="getValidUrl(footer.url1)"
          target="_blank"
        >
          {{ footer.urlTitle1 }}
        </a>
        <a
          v-if="footer.url2 && footer.urlTitle2"
          :href="getValidUrl(footer.url2)"
          target="_blank"
        >
          {{ footer.urlTitle2 }}
        </a>
        <a
          v-if="footer.url3 && footer.urlTitle3"
          :href="getValidUrl(footer.url3)"
          target="_blank"
        >
          {{ footer.urlTitle3 }}
        </a>
      </div>
      <div
        v-else
        class="flex-row justify-center container additional-footer-links"
      >
        <a
          class="cky-banner-element"
        >{{ $t('footer_bar.cookie_settings.label') }}
        </a>
        <a
          :href="$t('footer_bar.legal_aspects.link')"
          target="_blank"
        >
          {{ $t('footer_bar.legal_aspects.label') }}
        </a>
        <a
          :href="$t('footer_bar.imprint.link')"
          target="_blank"
        >
          {{ $t('footer_bar.imprint.label') }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterBar',
  data() {
    return {
      footer: {
        line1: '',
        line2: '',
        line3: '',
        email: '',
        website: '',
        phoneNumber: '',
        url1: '',
        urlTitle1: '',
        url2: '',
        urlTitle2: '',
        url3: '',
        urlTitle3: ''
      }
    };
  },
  computed: {
    line1() {
      return this.footer.line1 || this.$t('footer_bar.line1');
    },
    hasAtLeastOneDefaultUrl() {
      return (
        (this.footer.url1 && this.footer.urlTitle1)
        || (this.footer.url2 && this.footer.urlTitle2)
        || (this.footer.url3 && this.footer.urlTitle3)
      );
    }
  },
  methods: {
    getValidUrl(url) {
      if (url && !url.startsWith('http')) {
        return `http://${url}`;
      }
      return url;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/variables";

.page-footer {
  .footer-bar {
    line-height: 1.92;
    width: 100%;
    background-color: white;
    margin: auto;
    max-width: 1064px;
  }

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding-top: 32px;
  }

  .container.additional-footer-links {
    padding: 0 1rem 1rem 1rem;
  }

  .title {
    line-height: 1.93;
  }

  .address-container {
    margin: 0 60px;
  }

  .additional-footer-links {
    margin-bottom: 1rem;
    a {
      &:after {
        content: "|";
        color: $std-label;
        margin: 0 0.5rem;
        display: inline-block;
      }

      &:last-child:after {
        content: "";
      }
    }
  }
}

@media (min-width: 481px) {
  .footer-content > div {
    max-width: calc(33% - 40px);

    span, a {
      overflow: hidden;
      text-overflow:ellipsis;
    }
  }
}

@media (max-width: 480px) {
  .page-footer {
    .footer-content {
      flex-direction: column;
    }

    .title {
      margin-bottom: 1em;
    }

    .address-container {
      margin: 0 0 1em 0;
    }

    .additional-footer-links {
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      a {
        &:after {
          content: "";
        }
      }
    }
  }
}
</style>
