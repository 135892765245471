import Vue from 'vue';
import VueSanitize from 'vue-sanitize';
import VueGtag from 'vue-gtag';
import Sentry from '@/setup/sentry';
import i18n from '@/setup/i18n-setup';

import App from './App.vue';
import router from './router';

Sentry.init();

Vue.config.productionTip = false;

Vue.use(VueSanitize, {
  allowedTags: ['a', 'b', 'span', 'i', 'em', 's', 'u', 'strong', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'li', 'ol', 'ul', 'p', 'br', 'hr'],
  selfClosing: ['br', 'hr'],
  allowedAttributes: {
    a: ['href', 'target'],
    li: ['class'],
    '*': ['style']
  }
});

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_BASE_ID,
    params: { send_page_view: false }
  }
}, router);

window.vm = new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
