import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default {
  init() {
    Sentry.init({
      Vue,
      logErrors: true,
      release: `kickbox@${process.env.VUE_APP_VERSION}`,
      dsn: process.env.VUE_APP_SENTRY_DSN || '',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
      environment: process.env.VUE_APP_EXTERNAL_APPLICATION_ENV,
      tracingOptions: {
        trackComponents: true,
      },
      ignoreErrors: [
        'ResizeObserver loop limit exceeded'
      ],
      denyUrls: [
        'gtag/js',
        'gtag.js'
      ]
    });
  }
};
