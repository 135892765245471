import Vue from 'vue';
import Router from 'vue-router';

// Import routes
const SignupLogin = () => import(/* webpackChunkName: "SignupLogin" */ '@/components/signupLogin/SignupLogin');
const MigrationLogin = () => import(/* webpackChunkName: "MigrationLogin" */ '@/components/migration/MigrationLogin');
const CustomerExtraMile = () => import(/* webpackChunkName: "CustomerExtraMile" */ '@/components/book/CustomerExtraMile');

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);

const router = new Router({
  routes: [
    {
      alias: '/', path: '/login', name: 'SignupLogin', component: SignupLogin, props: true
    }, {
      path: '/migration', name: 'MigrationLogin', component: MigrationLogin, props: true
    }, {
      path: '/open-book/chapter/:chapter/page/:page', alias: '/open-blue-book/chapter/:chapter/page/:page', name: 'OpenExtraMile', component: CustomerExtraMile, props: ({ params }) => {
        const chapter = Number.parseInt(params.chapter || 1, 10);
        const page = Number.parseInt(params.page || 1, 10);
        return { chapter, page };
      }
    }, {
      path: '/book/chapter/:chapter?/page/:page?/:open?', alias: '/blue-book/chapter/:chapter?/page/:page?/:open?', name: 'CustomerExtraMile', component: CustomerExtraMile, props: ({ params }) => {
        const chapter = Number.parseInt(params.chapter || 1, 10);
        const page = Number.parseInt(params.page || 1, 10);
        return { chapter, page, open: !!params.open };
      }
    }
  ],

  scrollBehavior(to, from) {
    const hasQueryParams = !!Object.keys(to.query).length;
    const differentRoute = from.path !== to.path;

    if (differentRoute || !hasQueryParams) {
      if (!to.hash) {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      const element = document.querySelector(to.hash) || {};
      setTimeout(() => window.scrollTo({
        top: element.offsetTop, behavior: 'smooth', block: 'start'
      }), 100);
    }
    return true;
  }
});

export default router;
