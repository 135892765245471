import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getBrowserLanguage } from '@/util';

Vue.use(VueI18n);

const isDev = process.env.NODE_ENV === 'development';

const languageFiles = require.context('./locale', true, /\.json$/);

const loadedLanguages = languageFiles.keys().reduce((languages, path) => {
  const lang = path.split('/').pop().replace('.json', '').toUpperCase();
  languages[lang] = languageFiles(path);
  return languages;
}, {});

const currentUserLanguage = getBrowserLanguage();
const i18n = new VueI18n({
  locale: currentUserLanguage, fallbackLocale: 'EN', silentTranslationWarn: isDev
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang.toLowerCase());
  return lang;
}

async function fetchLanguage(lang) {
  return loadedLanguages[lang];
}

export async function loadLanguageAsync(lang = 'EN') {
  const msgs = await fetchLanguage(lang);
  i18n.setLocaleMessage(lang, msgs);
  return Promise.resolve(setI18nLanguage(lang));
}

// Load initial languages
loadLanguageAsync('EN');
if (currentUserLanguage !== 'EN') {
  loadLanguageAsync(currentUserLanguage);
}

export default i18n;
