<template>
  <div class="flex-row flex-center">
    <a
      class="logo-link"
      @click="$emit('click')"
    >
      <img src="@/assets/img/new_kickbox_logo.svg">
    </a>
    <img
      class="small-divider"
      src="@/assets/img/small_divider.svg"
    >
  </div>
</template>

<script>
export default {
  name: 'CompanyLogo',
};
</script>

<style scoped>
.logo-link {
  line-height: 0;
}

.small-divider {
  margin-left: 0.3rem;
}
@media (max-width: 500px) {
  .small-divider {
    display: none;
  }
}
</style>
