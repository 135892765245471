<template>
  <div id="app">
    <header-bar id="header-bar" />
    <router-view id="router-view" />
    <footer-bar />
  </div>
</template>

<script>
import HeaderBar from '@/components/header/HeaderBar';
import FooterBar from '@/components/FooterBar';

export default {
  name: 'App',
  components: { HeaderBar, FooterBar}
};
</script>

<style lang="scss">
@import "./assets/main.scss";

.ql-editor {
  padding: 0 !important;
  ul {
    padding-left: 0 !important;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

.container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.container--small {
  max-width: 480px;
}

section.container, .book-container, .loginsignup {
  margin: 50px auto;
  min-height: calc(100vh - 310px);
}

.loginsignup .container {
  margin: 50px auto;
  min-height: calc(100vh - 340px);
}

#header-bar {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
  height: 72px;
}

#router-view {
  margin-top: 84px;
  z-index: -1;
}

@media (max-width: 480px) {
  .container {
    padding: 1rem 1.5rem;
  }

  #router-view {
    margin-top: 5rem;
  }

  .lock-scroll {
    overflow: hidden;
  }
}
</style>
